module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":0,"duration":700},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"interval":500,"timeout":30000,"web":[{"name":"Oswald","file":"https://fonts.googleapis.com/css2?family=Oswald"},{"name":"Raleway","file":"https://fonts.googleapis.com/css2?family=Raleway"},{"name":"Manrope","file":"https://fonts.googleapis.com/css2?family=Manrope"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Unlock Your English","short_name":"unlockyourenglish","start_url":"/","background_color":"#1D3E84","theme_color":"#CE152B","display":"standalone","icon":"src/images/unlock-your-english-icon.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
